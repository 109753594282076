import { RichText } from 'components/Text/RichText';
import { CmsApiRichTextBlockData } from 'modules/cms/api/types/blocks/CmsApiRichTextBlock';

type Props = {
  blockIndex: number;
  data: CmsApiRichTextBlockData;
};

export function CmsRichTextBlock({ blockIndex, data: { html } }: Props) {
  return (
    <RichText
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      analyticsData={{
        eventName: 'CMS Block',
        eventProperties: {
          block_position: blockIndex,
          block_type: 'Rich Text',
        },
      }}
    />
  );
}
